import Layout from '@/layout';

const accessControl = {
  path: '/category',
  component: Layout,
  redirect: { name: 'CategoryList' },
  meta: {
    roles: ['admin'],
    title: 'Categories',
    icon: 'fas fa-th',
  },
  children: [
    {
      path: 'view/:id',
      component: () => import('@/views/category/view'),
      name: 'CategoryView',
      meta: {
        title: 'View Category',
        noCache: true,
        activeMenu: '/category/list',
        roles: ['admin'],
      },
      hidden: true,
    },
    {
      path: 'list',
      component: () => import('@/views/category/list'),
      name: 'CategoryList',
      meta: {
        title: 'Categories',
        roles: ['admin'],
        breadcrumb: false,
      },
    },
  ],
};

export default accessControl;
