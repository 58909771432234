import _ from 'lodash';
import Vue from 'vue';
import store from '@/store';

const needErrorLog = ['production', 'development'];

function checkNeed() {
  const env = process.env.NODE_ENV;
  if (_.isString(needErrorLog)) {
    return env === needErrorLog;
  }
  if (_.isArray(needErrorLog)) {
    return needErrorLog.includes(env);
  }
  return false;
}

if (checkNeed()) {
  Vue.config.errorHandler = (err, vm, info) => {
    Vue.nextTick(() => {
      store.dispatch('errorLog/addErrorLog', {
        err: Object.freeze(err),
        vm: Object.freeze(vm),
        info: Object.freeze(info),
        url: window.location.href,
      });
      // eslint-disable-next-line
      console.error(err, info);
    });
  };
}
