import Vue from 'vue';

Vue.mixin({
  watch: {
    tableData(val) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.$options._componentTag === 'el-table' && val.length > 0) {
        const parentNode = this.$el.parentElement || this.$el.parentNode;
        if (!parentNode.closest('.el-table__expanded-cell') && parentNode.closest('.el-table')) {
          return;
        }
        this.$nextTick(() => {
          const headerCells = this.$el.querySelectorAll('.el-table__header th:not(.gutter)');
          headerCells.forEach((hCell) => {
            const classes = hCell.className.split(' ');
            if (classes.length > 0) {
              const bodyCells = this.$el.querySelectorAll(`.el-table__body .${classes[0]}`);
              bodyCells.forEach((bCell) => {
                bCell.setAttribute('data-label', hCell.textContent);
              });
            }
          });
        });
      }
    },
  },
  methods: {
    //
  },
});
