// Getters for the account module

const getters = {
  id: (state) => state.id,
  username: (state) => state.username,
  roles: (state) => state.roles,
  token: (state) => state.token,
};

export default getters;
