/* ============
 * v-viewer
 * ============
 *
 * Image viewer component for vue, supports rotation, scale, zoom and so on, based on viewer.js
 *
 * https://github.com/mirari/v-viewer
 */

import Vue from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';

Vue.use(Viewer);
