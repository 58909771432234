<template>
  <div
    :class="classObj"
    class="app-wrapper"
  >
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    ></div>
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <div :class="{ 'fixed-header' : fixedHeader }">
        <navbar />
      </div>
      <app-main />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { AppMain, Navbar, Sidebar } from './components';
  import ResizeMixin from './mixin/ResizeHandler';

  export default {
    name: 'Layout',
    components: {
      AppMain,
      Navbar,
      Sidebar,
    },
    mixins: [ResizeMixin],
    data() {
      return {
        fixedHeader: true,
      };
    },
    computed: {
      ...mapGetters({
        sidebar: 'app/sidebar',
        device: 'app/device',
      }),
      classObj() {
        return {
          hideSidebar: !this.sidebar.opened,
          openSidebar: this.sidebar.opened,
          withoutAnimation: this.sidebar.withoutAnimation,
          mobile: this.device === 'mobile',
        };
      },
    },
    watch: {
      $route() {
        const { name } = this.$route;
        if (name) {
          this.$store.dispatch('app/addCachedView', this.$route);
        }
      },
    },
    methods: {
      handleClickOutside() {
        this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
      },
    },
  };
</script>

<style lang="scss">
  @import "../styles/components/_layout.scss";
</style>
