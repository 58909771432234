import Vue from 'vue';
import Vuex from 'vuex';

// import createLogger from 'vuex/dist/logger';

// Modules
import app from './modules/app';
import account from './modules/account';
import errorLog from './modules/errorLog';
import permission from './modules/permission';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app,
    account,
    errorLog,
    permission,
  },

  // If strict mode should be enabled.
  strict: debug,

  // Plugins used in the store.
  // plugins: debug ? [createLogger()] : [],
});
