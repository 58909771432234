// Mutations for the permission module

const mutations = {
  setRoutes: (state, { accessedRoutes, constantRoutes }) => {
    state.addRoutes = Object.freeze(accessedRoutes);
    state.routes = Object.freeze(constantRoutes.concat(accessedRoutes));
  },
};

export default mutations;
