import Layout from '@/layout';

const search = {
  path: '/logger',
  component: Layout,
  redirect: { name: 'listGroups' },
  children: [
    {
      path: 'list',
      component: () => import('@/views/logger/listGroups'),
      name: 'ListGroups',
      meta: {
        title: 'Logger',
        icon: 'fas fa-sensor-on',
        roles: ['admin'],
      },
    },
    {
      path: 'view/:logGroupHash/:logEventUid?',
      component: () => import('@/views/logger/viewLog'),
      name: 'ViewLog',
      meta: {
        title: 'View Log',
        noCache: true,
        roles: ['admin'],
        activeMenu: '/logger/list',
      },
      hidden: true,
    },
  ],
};

export default search;
