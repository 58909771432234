<template>
  <div v-if="errorLogs.length > 0">
    <el-badge
      :is-dot="true"
      @click.native="dialogTableVisible = true"
    >
      <el-button
        size="small"
        type="danger"
      >
        <i class="fas fa-bug"></i>
      </el-button>
    </el-badge>

    <el-dialog
      :visible.sync="dialogTableVisible"
      width="80%"
      append-to-body
    >
      <div slot="title">
        <span class="title">
          Error Log
        </span>
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-delete"
          @click="clearAll"
        >
          Clear All
        </el-button>
      </div>
      <el-table
        :data="errorLogs"
        border
      >
        <el-table-column label="Message">
          <template slot-scope="{row}">
            <div>
              <span class="message-title">Msg:</span>
              <el-tag type="danger">
                {{ row.err.message }}
              </el-tag>
            </div>
            <br />
            <div>
              <span class="message-title message-title--info">
                Info:
              </span>
              <el-tag type="warning">
                {{ row.vm.$vnode.tag }} error in {{ row.info }}
              </el-tag>
            </div>
            <br />
            <div>
              <span class="message-title message-title--url">
                Url:
              </span>
              <el-tag type="success">
                {{ row.url }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Stack">
          <template slot-scope="scope">
            {{ scope.row.err.stack }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'ErrorLog',
    data() {
      return {
        dialogTableVisible: false,
      };
    },
    computed: {
      ...mapGetters({
        errorLogs: 'errorLog/errorLogs',
      }),
    },
    methods: {
      clearAll() {
        this.dialogTableVisible = false;
        this.$store.dispatch('errorLog/clearErrorLog');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../styles/components/_errorLog.scss";
</style>
