import { router } from '@/plugins/vue-router';
import store from '@/store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken } from '@/utils/auth';
import { asyncRoutes, constantRoutes } from '@/routes';

NProgress.configure({ showSpinner: false });

const whiteList = ['/login']; // No redirect whitelist

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  if (to.meta.title) {
    document.title = `${to.meta.title} - Akcijos.lt Admin`;
  }

  // Determine whether the user has logged in
  const hasToken = getToken();

  if (hasToken) {
    if (to.path === '/login') {
      // If is logged in, redirect to the home page
      next({ path: '/' });
      NProgress.done();
    } else {
      // Determine whether the user has obtained his routes
      const currentRoutes = store.getters['permission/routes'];
      const hasRoutes = currentRoutes && currentRoutes.length > 0;
      if (hasRoutes) {
        next();
      } else {
        try {
          const info = await store.dispatch('account/getInfo');
          // Generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', {
            roles: info.roles,
            asyncRoutes,
            constantRoutes,
          });
          // Dynamically add accessible routes
          accessRoutes.forEach((route) => {
            router.addRoute(route);
          });
          // Det the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          // Remove token and go to login page to re-login
          store.dispatch('account/resetToken');
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    // In the free login whitelist, go directly
    next();
  } else {
    // Other pages that do not have permission to access are redirected to the login page
    next(`/login?redirect=${to.path}`);
    NProgress.done();
  }
});

router.afterEach(() => {
  NProgress.done();
});
