<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb
      id="breadcrumb-container"
      class="breadcrumb-container"
    />

    <div class="right-menu">
      <template>
        <error-log class="right-menu-item hover-effect" />
      </template>

      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
          <vue-letter-avatar
            :name="username"
            size="30"
            :rounded="true"
            class="user-avatar"
          />
        </div>
        <el-dropdown-menu slot="dropdown">
          <div class="dropdown-account">
            <div class="dropdown-name">
              {{ username }}
            </div>
          </div>
          <router-link :to="{name: 'MyProfile'}">
            <el-dropdown-item divided>
              <i class="fal fa-id-card"></i> My Profile
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item
            divided
            @click.native="logout"
          >
            <i class="far fa-sign-out"></i> Log Out
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import ErrorLog from '@/components/ErrorLog';

export default {
  components: {
    Breadcrumb,
    Hamburger,
    ErrorLog,
  },
  computed: {
    ...mapGetters({
      sidebar: 'app/sidebar',
      username: 'account/username',
      device: 'app/device',
    }),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await this.$store.dispatch('account/logout');
      await this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/components/_navbar.scss";
</style>
