<template>
  <!-- eslint-disable vue/require-component-is -->
  <component v-bind="linkProps(to)">
    <slot></slot>
  </component>
</template>

<script>
  export default {
    name: 'SidebarLink',
    props: {
      to: {
        type: String,
        required: true,
      },
    },
    methods: {
      isExternal(path) {
        return /^(https?:|mailto:|tel:)/.test(path);
      },
      linkProps(url) {
        if (this.isExternal(url)) {
          return {
            is: 'a',
            href: url,
            target: '_blank',
            rel: 'noopener',
          };
        }
        return {
          is: 'router-link',
          to: url,
        };
      },
    },
  };
</script>
