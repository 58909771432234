// Actions for the app module

const actions = {
  toggleSideBar({ commit }) {
    commit('toggleSidebar');
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('closeSidebar', withoutAnimation);
  },
  toggleDevice({ commit }, device) {
    commit('toggleDevice', device);
  },
  addCachedView({ commit }, view) {
    commit('addCachedView', view);
  },
  delCachedView({ commit, state }, view) {
    return new Promise((resolve) => {
      commit('delCachedView', view);
      resolve([...state.cachedViews]);
    });
  },
  delAllCachedViews({ commit, state }) {
    return new Promise((resolve) => {
      commit('delAllCachedViews');
      resolve([...state.cachedViews]);
    });
  },
  setBadgeValue({ commit }, { id, value }) {
    commit('setBadgeValue', { id, value });
  },
};

export default actions;
