import Layout from '@/layout';

const accessControl = {
  path: '/publication',
  component: Layout,
  redirect: { path: 'PublicationPending' },
  meta: {
    title: 'Publications',
    icon: 'fas fa-sticky-note',
    roles: ['admin'],
    badge: 'pendingPublicationsCount',
  },
  children: [
    {
      path: 'view/:id',
      component: () => import('@/views/publication/view'),
      name: 'PublicationView',
      meta: {
        title: 'View Publication',
        noCache: true,
        activeMenu: '/publication/list',
        roles: ['admin'],
      },
      hidden: true,
    },
    {
      path: 'list/pending/:id?',
      component: () => import('@/views/publication/list'),
      name: 'PublicationPending',
      meta: {
        title: 'Pending',
        roles: ['admin'],
        icon: 'fas fa-history',
        status: 'pending',
        badge: 'pendingPublicationsCount',
      },
    },
    {
      path: 'list/active',
      component: () => import('@/views/publication/list'),
      name: 'PublicationActive',
      meta: {
        title: 'Active',
        roles: ['admin'],
        icon: 'fas fa-check-circle',
        status: 'active',
      },
    },
    {
      path: 'list/archived',
      component: () => import('@/views/publication/list'),
      name: 'PublicationArchived',
      meta: {
        title: 'Archived',
        roles: ['admin'],
        icon: 'fas fa-archive',
        status: 'archived',
      },
    },
    {
      path: 'list/deleted',
      component: () => import('@/views/publication/list'),
      name: 'PublicationDeleted',
      meta: {
        title: 'Deleted',
        roles: ['admin'],
        icon: 'fas fa-trash',
        status: 'deleted',
      },
    },
    {
      path: 'list/processing',
      component: () => import('@/views/publication/list'),
      name: 'PublicationProcessing',
      meta: {
        title: 'Processing',
        roles: ['admin'],
        icon: 'fas fa-upload',
        status: 'processing',
        badge: 'processingPublicationsCount',
      },
    },
  ],
};

export default accessControl;
