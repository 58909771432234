<script>
  export default {
    name: 'SidebarMenuBadge',
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        value: '',
      };
    },
    mounted() {
      this.listenOnRoot('sidebar:badge', this.setValue);
      const value = this.$store.getters['app/getBadgeValue'](this.id);
      if (value) {
        this.value = value;
      }
    },
    methods: {
      setValue(id, value) {
        if (id === this.id) {
          this.value = value;
          this.$store.dispatch('app/setBadgeValue', { id, value });
        }
      },
      listenOnRoot(event, callback) {
        this.$root.$on(event, callback);
        this.$on('hook:beforeDestroy', () => {
          this.$root.$off(event, callback);
        });
      },
    },
    render(h) {
      const attrs = {
        class: 'sidebar-badge',
      };
      return this.value ? h('span', attrs, this.value) : h();
    },
  };
</script>
