// State of the app module

const state = {
  sidebar: {
    opened: localStorage.getItem('sidebarStatus') ? !!+localStorage.getItem('sidebarStatus') : true,
    withoutAnimation: false,
  },
  device: 'desktop',
  cachedViews: [],
  badges: [],
};

export default state;
