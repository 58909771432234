import Vue from 'vue';

export async function login(data) {
  return Vue.$http({
    url: '/adminUsers/login',
    method: 'post',
    data,
  });
}

export async function logout() {
  return Vue.$http({
    url: '/adminUsers/logout',
    method: 'post',
  });
}

export function getInfo() {
  return Vue.$http({
    url: '/adminUsers/me',
    method: 'get',
  });
}

export function updateSettings(data) {
  return Vue.$http({
    url: '/adminUsers/updateSettings',
    method: 'post',
    data,
  });
}
