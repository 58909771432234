// Mutations for the app module

const mutations = {
  toggleSidebar: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      localStorage.setItem('sidebarStatus', '1');
    } else {
      localStorage.setItem('sidebarStatus', '0');
    }
  },
  closeSidebar: (state, withoutAnimation) => {
    localStorage.setItem('sidebarStatus', '0');
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  toggleDevice: (state, device) => {
    state.device = device;
  },
  addCachedView: (state, view) => {
    if (state.cachedViews.includes(view.name)) {
      return;
    }
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name);
    }
  },
  delCachedView: (state, view) => {
    const index = state.cachedViews.indexOf(view.name);
    if (index > -1) {
      state.cachedViews.splice(index, 1);
    }
  },
  delAllCachedViews: (state) => {
    state.cachedViews = [];
  },
  setBadgeValue: (state, { id, value }) => {
    state.badges[id] = value;
  },
};

export default mutations;
