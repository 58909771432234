<template>
  <div
    class="hamburger"
    @click="toggleClick"
  >
    <div
      class="far icon"
      :class="[ isActive ? 'fa-outdent' : 'fa-indent' ]"
    ></div>
  </div>
</template>

<script>
  export default {
    name: 'Hamburger',
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      toggleClick() {
        this.$emit('toggleClick');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../styles/components/_hamburger.scss";
</style>
