<template>
  <el-breadcrumb
    class="app-breadcrumb"
    separator="/"
  >
    <transition-group name="breadcrumb">
      <el-breadcrumb-item
        v-for="(item, index) in levelList"
        :key="item.path"
      >
        <span
          v-if="item.redirect === 'noRedirect' || index === levelList.length - 1"
          class="no-redirect"
        >
          {{ item.meta.title }}
        </span>
        <a
          v-else
          @click.prevent="handleLink(item)"
        >
          {{ item.meta.title }}
        </a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
  import { pathToRegexp } from 'path-to-regexp';

  export default {
    data() {
      return {
        levelList: null,
      };
    },
    watch: {
      $route(route) {
        if (route.path.startsWith('/redirect/')) {
          return;
        }
        this.getBreadcrumb();
      },
    },
    created() {
      this.getBreadcrumb();
    },
    methods: {
      getBreadcrumb() {
        // Only show routes with meta.title
        const matched = this.$route.matched.filter((item) => item.meta && item.meta.title);
        /* const first = matched[0];
        if (!this.isDashboard(first)) {
          matched = [{ path: '/dashboard', meta: { title: 'Dashboard' } }].concat(matched);
        } */
        this.levelList = matched.filter(
          (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false,
        );
      },
      /* isDashboard(route) {
        const name = route && route.name;
        if (!name) {
          return false;
        }
        return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
      }, */
      pathCompile(path) {
        const { params } = this.$route;
        const toPath = pathToRegexp.compile(path);
        return toPath(params);
      },
      handleLink(item) {
        const { redirect, path } = item;
        if (redirect) {
          this.$router.push(redirect);
          return;
        }
        this.$router.push(this.pathCompile(path));
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../styles/components/_breadcrumb.scss";
</style>
