import Vue from 'vue';

export function listPublications(params) {
  return Vue.$http({
    url: '/publications/listPublications',
    method: 'get',
    params,
  });
}

export function getPublication(params) {
  return Vue.$http({
    url: '/publications/getPublication',
    method: 'get',
    params,
  });
}

export function updatePublication(data) {
  return Vue.$http({
    url: '/publications/updatePublication',
    method: 'post',
    data,
  });
}

export function createPublication(data) {
  return Vue.$http({
    url: '/publications/createPublication',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}

export function deletePublication(data) {
  return Vue.$http({
    url: '/publications/deletePublication',
    method: 'post',
    data,
  });
}

export function getPublicationsCount(params) {
  return Vue.$http({
    url: '/publications/getPublicationsCount',
    method: 'get',
    params,
  });
}
