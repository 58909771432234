/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import { getToken } from '@/utils/auth';
import { Message, MessageBox } from 'element-ui';

axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;
axios.defaults.timeout = 5000;
axios.defaults.headers.common.Accept = 'application/json';

axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    let errMsg = error.message || 'Has Error';
    if (error.response.data && error.response.data.error) {
      errMsg = error.response.data.error.message || error.response.data.error.code;
    }
    if (error.response.data.error.code === 'sessionNotFound') {
      // Determine whether the user has obtained his routes
      const currentRoutes = store.getters['permission/routes'];
      const hasRoutes = currentRoutes && currentRoutes.length > 0;
      if (hasRoutes) {
        MessageBox.confirm(
          'Your session has expired. Would you like to be redirected to the login page?',
          'Session Expired',
          {
            type: 'warning',
            callback(action) {
              if (action === 'confirm') {
                store.dispatch('account/resetToken')
                  .then(() => {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                  });
              }
            },
          },
        );
      }
    } else {
      Message({
        message: errMsg,
        type: 'error',
        duration: 5000,
      });
    }
    return Promise.reject(error);
  },
);

// Bind Axios to Vue
Vue.$http = axios;
Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return axios;
  },
});
