<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { getPublicationsCount } from '@/api/publication';

  export default {
    name: 'AdminPanel',
    data() {
      return {
        publicationsCountTm: null,
      };
    },
    computed: {
      ...mapGetters({
        accountRoles: 'account/roles',
        accountToken: 'account/token',
      }),
    },
    watch: {
      accountRoles(val) {
        if (val.length && this.publicationsCountTm === null) {
          this.getPublicationsCount();
        }
      },
    },
    methods: {
      async getPublicationsCount() {
        const dataPending = await getPublicationsCount({ status: 'pending' });
        const dataProcessing = await getPublicationsCount({ status: 'processing' });

        this.$root.$emit('sidebar:badge', 'pendingPublicationsCount', dataPending.count);
        this.$root.$emit('sidebar:badge', 'processingPublicationsCount', dataProcessing.count);

        if (this.accountToken) {
          this.publicationsCountTm = setTimeout(this.getPublicationsCount, 15000);
        }
      },
    },
  };
</script>
