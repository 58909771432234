/* ============
 * vue-letter-avatar
 * ============
 *
 * An elegant and easy to use letter avatar component for vue.js 2.0+
 *
 * https://github.com/aidewoode/vue-letter-avatar
 */

import Vue from 'vue';

import VueLetterAvatar from 'vue-letter-avatar';

Vue.use(VueLetterAvatar);
