import Cookies from 'js-cookie';

const tokenKey = 'accessToken';

export function getToken() {
  return Cookies.get(tokenKey);
}

export function setToken(token, expires) {
  return Cookies.set(tokenKey, token, { expires });
}

export function removeToken() {
  return Cookies.remove(tokenKey);
}

export function clearLocalStorage() {
  return localStorage.clear();
}
