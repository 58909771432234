<template>
  <section class="app-main">
    <keep-alive :include="cachedViews">
      <router-view :key="key" />
    </keep-alive>
    <back-to-top
      :visibility-height="300"
      transition-name="fade"
    />
  </section>
</template>

<script>
import BackToTop from '@/components/BackToTop';
import { mapGetters } from 'vuex';

export default {
  name: 'AppMain',
  components: {
    BackToTop,
  },
  computed: {
    ...mapGetters({
      cachedViews: 'app/cachedViews',
    }),
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/components/_appMain.scss";
</style>
