// Actions for the errorLog module

const actions = {
  addErrorLog({ commit }, log) {
    commit('addErrorLog', log);
  },
  clearErrorLog({ commit }) {
    commit('clearErrorLog');
  },
};

export default actions;
