/* eslint-disable no-bitwise */
import { format } from 'date-fns';

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return `${time + label}s`;
}

/**
 * @param {date} date
 */
export function timeAgo(date) {
  const between = Date.now() / 1000 - new Date(date) / 1000;
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  }
  if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  }
  return pluralize(~~(between / 86400), ' day');
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i += 1) {
    if (num >= si[i].value) {
      return (num / si[i].value)
        .toFixed(digits)
        .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Format date
 * @param {String} ISO date
 *
 * @returns {String} Formatted date
 */
export function formatDate(dateStr) {
  if (dateStr) {
    const date = new Date(dateStr);
    return format(date, 'yyyy-MM-dd HH:mm');
  }
  return '';
}

/**
 * Format seconds
 * @param {Number}
 *
 * @returns {String} Formatted time
 */

export function secToTime(timeInSeconds) {
  if (timeInSeconds) {
    const pad = (num, size) => (`000${num}`).slice(size * -1);
    const time = parseFloat(timeInSeconds).toFixed(3);
    const hours = Math.floor(time / 60 / 60);
    const minutes = Math.floor(time / 60) % 60;
    const seconds = Math.floor(time - minutes * 60);

    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
  }

  return 'N/A';
}

/**
 * Trim uid
 * @param {String} uid
 *
 * @returns {String} trimmed uid
 */
export function trimUid(uid) {
  if (!uid) {
    return '-';
  }
  const parts = uid.split('-');
  return `${parts[0]}...`;
}

export function adminUserStatusFilter(status) {
  const statusMap = {
    active: 'success',
    disabled: 'danger',
  };
  return statusMap[status];
}
