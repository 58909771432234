<script>
  export default {
    name: 'SidebarMenuItem',
    functional: true,
    props: {
      icon: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
    },
    render(h, context) {
      const { icon, title } = context.props;
      const vNodes = [];
      if (icon) {
        const cl = `icon ${icon}`;
        vNodes.push(<span class={cl}></span>);
      }
      if (title) {
        vNodes.push(<span slot="title">{(title)}</span>);
      }
      return vNodes;
    },
  };
</script>
