// Mutations for the account module

const mutations = {
  setId: (state, id) => {
    state.id = id;
  },
  setUsername: (state, username) => {
    state.username = username;
  },
  setRoles: (state, roles) => {
    state.roles = roles;
  },
  setToken: (state, token) => {
    state.token = token;
  },
};

export default mutations;
