<template>
  <div v-if="!item.hidden">
    <template
      v-if="hasOneShowingChild(item.children,item)
        && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow"
    >
      <app-link
        v-if="onlyOneChild.meta"
        :to="resolvePath(onlyOneChild.path)"
      >
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <item
            :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
            :title="onlyOneChild.meta.title"
          />
          <badge
            v-if="onlyOneChild.meta && onlyOneChild.meta.badge"
            :id="onlyOneChild.meta.badge"
          />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path)"
      popper-append-to-body
    >
      <template slot="title">
        <item
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="item.meta.title"
        />
        <badge
          v-if="item.meta && item.meta.badge"
          :id="item.meta.badge"
        />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
  import path from 'path';
  import Item from './Item';
  import AppLink from './Link';
  import Badge from './Badge';

  export default {
    name: 'SidebarItem',
    components: { Item, AppLink, Badge },
    props: {
      // Route object
      item: {
        type: Object,
        required: true,
      },
      isNest: {
        type: Boolean,
        default: false,
      },
      basePath: {
        type: String,
        default: '',
      },
    },
    data() {
      this.onlyOneChild = null;
      return {};
    },
    methods: {
      isExternal(pathUrl) {
        return /^(https?:|mailto:|tel:)/.test(pathUrl);
      },
      hasOneShowingChild(children = [], parent) {
        const showingChildren = children.filter((item) => {
          if (item.hidden) {
            return false;
          }
          this.onlyOneChild = item;
          return true;
        });

        if (showingChildren.length === 1) {
          return true;
        }

        if (showingChildren.length === 0) {
          this.onlyOneChild = {
            ...parent,
            path: '',
            noShowingChildren: true,
          };
          return true;
        }
        return false;
      },
      resolvePath(routePath) {
        if (this.isExternal(routePath)) {
          return routePath;
        }
        if (this.isExternal(this.basePath)) {
          return this.basePath;
        }
        return path.resolve(this.basePath, routePath);
      },
    },
  };
</script>
