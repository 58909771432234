// State of the account module

import { getToken } from '@/utils/auth';

const state = {
  id: null,
  username: null,
  roles: [],
  token: getToken(),
};

export default state;
