// Actions for the account module

import { login, getInfo, logout } from '@/api/account';
import { setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/plugins/vue-router';

const actions = {
  async login({ commit }, { username, password }) {
    const response = await login({
      username: username.trim(),
      password,
    });
    const { session } = response;
    commit('setToken', session.Authorization);
    setToken(session.Authorization, new Date(session.expiresAt));
  },
  async logout({ commit, dispatch }) {
    await logout();
    commit('setToken', '');
    removeToken();
    resetRouter();
    dispatch('app/delAllCachedViews', null, { root: true });
  },
  resetToken({ commit }) {
    commit('setToken', '');
    removeToken();
  },
  async getInfo({ commit }) {
    const response = await getInfo();
    const { user } = response;
    commit('setId', user.userUid);
    commit('setUsername', user.username);
    commit('setRoles', [user.role]);
    user.roles = [user.role];
    return user;
  },
  setRoles({ commit }, roles) {
    commit('setRoles', roles);
  },
};

export default actions;
