import Vue from 'vue';

import 'normalize.css/normalize.css';

import './plugins/element-ui';
import './plugins/vuex';
import './plugins/axios';
import { router } from './plugins/vue-router';
import './plugins/vuex-router-sync';
import './plugins/avatar';
import './plugins/viewer';
import './plugins/vue-json-viewer';
import './plugins/editor';

import './mixins/global';

import './permission'; // permission control
import './utils/errorLog'; // error log

import * as filters from './filters'; // global filters

import '@/styles/index.scss';

import App from './App';
import store from './store';

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
