// Getters for the app  module

const getters = {
  sidebar: (state) => state.sidebar,
  device: (state) => state.device,
  cachedViews: (state) => state.cachedViews,
  getBadgeValue: (state) => (id) => state.badges[id] || null,
};

export default getters;
