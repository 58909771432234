<template>
  <div class="has-logo">
    <logo :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables['menu-bg']"
        :text-color="variables['menu-text']"
        :unique-opened="false"
        :active-text-color="variables['menu-active-text']"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in menuItems"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
  import variables from '@/styles/setup/_variables.scss';
  import { mapGetters } from 'vuex';
  import Logo from './Logo';
  import SidebarItem from './SidebarItem';

  export default {
    name: 'Sidebar',
    components: {
      SidebarItem,
      Logo,
    },
    data() {
      return {
        activeMenu: '',
      };
    },
    computed: {
      ...mapGetters({
        permissionRoutes: 'permission/routes',
        sidebar: 'app/sidebar',
      }),
      variables() {
        return variables;
      },
      isCollapse() {
        return !this.sidebar.opened;
      },
      menuItems() {
        const menuItems = { ...this.permissionRoutes };
        this.permissionRoutes.forEach((item, index) => {
          if (!item.hidden && item.name && item.children) {
            const filtered = item.children.filter((children) => !children.hidden);
            if (filtered.length === 0) {
              menuItems[index].hidden = true;
            }
          }
        });
        return this.permissionRoutes;
      },
    },
    async mounted() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        if (typeof meta.activeMenu === 'function') {
          this.activeMenu = await meta.activeMenu(this);
        } else {
          this.activeMenu = meta.activeMenu;
        }
      } else {
        this.activeMenu = path;
      }
    },
  };
</script>

<style lang="scss">
  @import "../../../styles/components/_sidebar.scss";
</style>
