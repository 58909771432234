import Layout from '@/layout';

const accessControl = {
  path: '/store',
  component: Layout,
  redirect: { name: 'StoreList' },
  meta: {
    title: 'Stores',
    icon: 'fas fa-store',
    roles: ['admin'],
  },
  children: [
    {
      path: 'view/:id',
      component: () => import('@/views/store/view'),
      name: 'StoreView',
      meta: {
        title: 'View Store',
        noCache: true,
        activeMenu: '/store/list',
        roles: ['admin'],
      },
      hidden: true,
    },
    {
      path: 'list',
      component: () => import('@/views/store/list'),
      name: 'StoreList',
      meta: {
        title: 'Stores',
        roles: ['admin'],
        breadcrumb: false,
      },
    },
  ],
};

export default accessControl;
