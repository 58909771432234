/* ============
 * Element UI
 * ============
 *
 * Element, a Vue 2.0 based component library for developers, designers and product managers
 *
 * https://element.eleme.io/
 */

import Vue from 'vue';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';

import '@/styles/setup/_element-variables.scss';

Vue.use(Element, { locale, size: 'medium' });
