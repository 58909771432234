/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import { constantRoutes as routes } from '@/routes';

Vue.use(VueRouter);

const createRouter = () => new VueRouter({
  // mode: 'history',
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
});

export const router = createRouter();

Vue.router = router;

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
