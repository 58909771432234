import Vue from 'vue';
import VueHtml5Editor from 'vue-html5-editor';

Vue.use(VueHtml5Editor, {
  name: 'html-editor',
  showModuleName: false,
  visibleModules: [
    'text',
    'color',
    'align',
    'list',
    'link',
    'unlink',
    'tabulation',
    // 'image',
    // 'hr',
    'eraser',
    'undo',
    // 'full-screen',
    // 'info',
  ],
});
